<template>
  <div class="information">
    <div class="common">
      <div :class="index == 0 ? 'action' : 'selected'" @click="setindex(0)">
        @我的（{{ total }}）
      </div>
      <div :class="index == 1 ? 'action' : 'selected'" @click="setindex(1)">
        评论（{{ Commenttotal }}）
      </div>

      <div :class="index == 2 ? 'action' : 'selected'" @click="setindex(2)">
        点赞（{{ ThumbsUptotal }}）
      </div>
    </div>
    <div class="conter" v-loading="loading" element-loading-text="拼命加载中">
      <transition name="el-zoom-in-center">
        <AITme
          :total="total"
          :list="list"
          @handleCurrentChange="handleCurrentChange"
          v-if="index == 0"
        />
      </transition>
      <transition name="el-zoom-in-center">
        <Comment
          :total="Commenttotal"
          @CommenthandleCurrentChange="CommenthandleCurrentChange"
          :Commentlist="Commentlist"
          v-if="index == 1"
        />
      </transition>
      <transition name="el-zoom-in-center">
        <ThumbsUp
          :total="ThumbsUptotal"
          @ThumbsUphandleCurrentChange="ThumbsUphandleCurrentChange"
          :ThumbsUplist="ThumbsUplist"
          v-if="index == 2"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import Comment from "./common/comment.vue";
import AITme from "./common/AITme.vue";
import ThumbsUp from "./common/thumbsUp.vue";
export default {
  data() {
    return {
      index: 0,
      loading: false,
      form: {
        token: window.localStorage.getItem("token"),
        Category: 2, //=圈子@提醒，3=圈子评论回复，4=圈子点赞
        pageIndex: 1,
        pageSize: 10,
      },
      Commentform: {
        token: window.localStorage.getItem("token"),
        Category: 3, //=圈子@提醒，3=圈子评论回复，4=圈子点赞
        pageIndex: 1,
        pageSize: 10,
      },
      ThumbsUpform: {
        token: window.localStorage.getItem("token"),
        Category: 4, //=圈子@提醒，3=圈子评论回复，4=圈子点赞
        pageIndex: 1,
        pageSize: 10,
      },
      list: [], //@数组
      total: 0, //@总数
      Commentlist: [], //评论数组
      Commenttotal: 0, //评论总数
      ThumbsUplist: [], //点赞数组
      ThumbsUptotal: 0, //评论总数
    };
  },
  components: {
    Comment,
    AITme,
    ThumbsUp,
  },
  created() {
    this.gettable();
    this.gettableComment();
    this.gettableThumbsUp();
  },
  methods: {
    // 艾特下一页
    handleCurrentChange(val) {
      this.form.pageIndex = val;
      this.gettable(val);
    },
    // 点赞下一页
    ThumbsUphandleCurrentChange(val) {
      this.ThumbsUpform.pageIndex = val;
      this.gettableThumbsUp(val);
    },
    // 评论下一页
    CommenthandleCurrentChange(val) {
      this.Commentform.pageIndex = val;
      this.gettableComment(val);
    },
    // 改变size
    handleSizeChange(val) {
      console.log(val);
    },
    // 艾特
    gettable(val) {
      if (val) {
        this.form.pageIndex = val;
      }
      this.$api.common.ReminderList(this.form).then((res) => {
        if (res.code == 200) {
          for (let index = 0; index < res.data.list.length; index++) {
            res.data.list[index].Content = this.RichContent(
              res.data.list[index].Content
            );
            res.data.list[index].FindData.Content = this.RichContent(
              res.data.list[index].FindData.Content
            );
          }
          this.list = res.data.list;

          this.total = res.data.total;
        }
      });
    },
    // 评论
    gettableComment(val) {
      if (val) {
        this.Commentform.pageIndex = val;
      }
      this.$api.common.ReminderList(this.Commentform).then((res) => {
        if (res.code == 200) {
          for (let index = 0; index < res.data.list.length; index++) {
            res.data.list[index].Content = this.RichContent(
              res.data.list[index].Content
            );
            res.data.list[index].FindData.Content = this.RichContent(
              res.data.list[index].FindData.Content
            );
          }
          this.Commentlist = res.data.list;
          this.Commenttotal = res.data.total;
        }
      });
    },
    // 点赞
    gettableThumbsUp(val) {
      if (val) {
        this.ThumbsUpform.pageIndex = val;
      }
      this.$api.common.ReminderList(this.ThumbsUpform).then((res) => {
        if (res.code == 200) {
          for (let index = 0; index < res.data.list.length; index++) {
            res.data.list[index].Content = this.RichContent(
              res.data.list[index].Content
            );
            res.data.list[index].FindData.Content = this.RichContent(
              res.data.list[index].FindData.Content
            );  
          }
          this.ThumbsUplist = res.data.list;
          this.ThumbsUptotal = res.data.total;
        }
      });
    },
    setindex(index) {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.index = index;
      }, 300);
    },
  },
};
</script>

<style lang='less' scoped>
@import url("./index.less");
</style>
