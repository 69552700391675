<template>
  <div class="commentbox">
    <div class="listbox" v-for="(item, index) in list" :key="index">
      <div class="topbox">
        <div class="avatarbox" @click="gotoOtherspersonal(item)">
          <img :src="item.Avatar" alt="" />
          <div class="Grade" v-if="item.Gender == '女'">{{ item.Grade }}</div>
          <div class="Grademan" v-if="item.Gender == '男'">
            {{ item.Grade }}
          </div>
        </div>
        <div class="userbox">
          <div class="name">{{ item.Name }}</div>
          <div class="time">{{ item.time }}</div>
        </div>
      </div>
      <div class="huifu" @click="gotoPostdetails(item)" v-html="item.Content"></div>
      <div class="contentbox" @click="gotoPostdetails(item)">
        <span></span><span style="color:#818181" v-html=" item.FindData.Content">{{ item.FindData.Content }}</span>
        <!-- <div class="demo-image__preview">
          <img style="width: 100px; height: 100px" :src="item.FindData.Image" />
        </div> -->
      </div>
      <el-divider></el-divider>
    </div>
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
        :hide-on-single-page="true"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: function () {
        return [];
      },
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    gotoPostdetails(item) {
      this.$router.push(`Postdetails?FindCode=${item.FindCode}`);
    },
    // 去用户首页
    gotoOtherspersonal(item) {
      this.$router.push(`Otherspersonal?id=${item.RelatedMemberCode}`);
    },
    // 下一页
    handleCurrentChange(val) {
      this.$emit("handleCurrentChange", val);
    },
  },
};
</script>

<style lang='less' scoped>
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #e6bf84 !important;
}
/deep/.el-divider--horizontal {
  background-color: #f8f8f8;
}
.pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
}
.commentbox {
  width: 100%;
  .listbox {
    width: 100%;
    // display: flex;
    .topbox {
      width: 100%;
      display: flex;
      align-items: center;

      .userbox {
        margin-left: 10px;
        .name {
          font-size: 18px;
          font-family: NotoSansHans;
          font-weight: 400;
          color: #818181;
        }
        .time {
          font-size: 14px;
          font-family: NotoSansHans;
          font-weight: 300;
          color: #818181;
          opacity: 0.6;
          margin-top: 3px;
        }
      }
      .avatarbox {
        width: 57.5px;
        height: 57.5px;
        border-radius: 50%;
        position: relative;
        img {
          width: 57.5px;
          height: 57.5px;
          border-radius: 50%;
          cursor: pointer;
        }
        .Grade {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 22px;
          height: 22px;
          background: #fa8fd1;
          border: 1px solid #ffffff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 13px;
          font-family: NotoSansHans;
          font-weight: 400;
          color: #ffffff;
        }
        .Grademan {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 22px;
          height: 22px;
          background: #836dfb;
          border: 1px solid #ffffff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 13px;
          font-family: NotoSansHans;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
    .huifu {
      font-size: 18px;
      font-family: NotoSansHans;
      font-weight: 300;
      color: #818181;
      margin-top: 10px;
      cursor: pointer;
    }
    .contentbox {
      width: 100%;
      background: #f8f8f8;
      border-radius: 10px;
      width: 100%;
      font-size: 12px;
      font-family: NotoSansHans;
      font-weight: 100;
      color: #818181;
      line-height: 26px;
      box-sizing: border-box;
      padding: 10px;
      margin-top: 20px;
      cursor: pointer;
      span {
        color: #e6bf84;
      }
    }
  }
}
</style>
